
.gallery-image {
    object-fit: cover;
    height: 300px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .gallery-image:hover {
    transform: scale(1.05);
  }
  

  